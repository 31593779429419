interface PaginationProps {
    totalItems: number;
    rowsPerPage: number;
    currentPage: number;
    onPageChange: (page: number) => void;
  }
  
 export const Pagination: React.FC<PaginationProps> = ({
    totalItems,
    rowsPerPage,
    currentPage,
    onPageChange,
  }) => {
    const totalPages = Math.ceil(totalItems / rowsPerPage);
  
    const handleClick = (page: number) => {
      if (page >= 1 && page <= totalPages) {
        onPageChange(page);
      }
    };
  
    return (
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <button onClick={() => handleClick(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handleClick(index + 1)}
            style={{
              margin: '0 5px',
              padding: '5px 10px',
              backgroundColor: currentPage === index + 1 ? '#007bff' : '#fff',
              color: currentPage === index + 1 ? '#fff' : '#007bff',
              border: '1px solid #007bff',
              borderRadius: '4px',
            }}
          >
            {index + 1}
          </button>
        ))}
        <button onClick={() => handleClick(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    );
  };
  
  export default Pagination;  