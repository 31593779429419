import React from 'react';
import styled from 'styled-components';
import TopNavBar from '../components/TopNavBar';
import BottomBar from '../components/BottomBar';

const ContactContainer = styled.div`
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

const Header = styled.h1`
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 20px;
`;

const SubHeader = styled.p`
  font-size: 1.2rem;
  color: #7f8c8d;
  margin-bottom: 40px;
`;

const ContactInfo = styled.div`
  font-size: 1.5rem;
  color: #34495e;
  line-height: 1.6;

  & > p {
    margin: 10px 0;
  }
`;

const Contact: React.FC = () => {
  return (
    <ContactContainer>
        <TopNavBar />
      <Header>Contact Us</Header>
      <SubHeader>If you have any questions, feel free to reach out!</SubHeader>
      <ContactInfo>
        <p>Email: <a href="mailto:admin@ressytech.com">admin@ressytech.com</a></p>
        <p>Email: <a href="mailto:socials@ressydent.com">socials@ressydent.com</a></p>
        <p>Phone: <a href="tel:+2348030768663">+234 803 076 8663</a></p>
      </ContactInfo>
      <BottomBar />
    </ContactContainer>
  );
};

export default Contact;