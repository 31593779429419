import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import TopNavBar from '../components/TopNavBar';
import BottomBar from '../components/BottomBar';

const HomeContainer = styled.div`
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

const NavBar = styled.nav`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
`;

const NavLink = styled(Link)`
  margin: 0 15px;
  text-decoration: none;
  color: #2980b9;
  font-size: 1.2rem;

  &:hover {
    text-decoration: underline;
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 40px;
`;

const Logo = styled.img`
  width: 200px;
  height: auto;
`;

const Header = styled.h1`
  font-size: 2.8rem;
  color: #2c3e50;
  margin-bottom: 20px;
`;

const SubHeader = styled.p`
  font-size: 1.2rem;
  color: #7f8c8d;
  line-height: 1.6;
  margin-bottom: 40px;
`;

const FeaturesSection = styled.section`
  margin: 40px 0;
`;

const FeaturesTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  color: #2980b9;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
`;

const FeatureCard = styled.div`
  background-color: #ecf0f1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #34495e;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: #7f8c8d;
  line-height: 1.6;
`;

const CallToAction = styled.div`
  margin-top: 40px;
`;

const CTAButton = styled.a`
  display: inline-block;
  padding: 15px 30px;
  font-size: 1.2rem;
  color: white;
  background-color: #2980b9;
  border-radius: 30px;
  text-decoration: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3498db;
  }
`;

const Home: React.FC = () => {
  return (
    <HomeContainer>
      {/* Navigation Bar */}
      <TopNavBar />

      {/* Logo Section */}
      <LogoContainer>
        {/* Logo from assets folder */}
        <Logo src={process.env.PUBLIC_URL + '/logo.png'} alt="Ressydent Logo" />
      </LogoContainer>

      {/* Welcome Section */}
      <Header>Welcome to Ressydent</Header>
      <SubHeader>
        Manage your estate with ease. Pay for electricity, service charges, and more — all from a
        single platform designed to simplify your life as a resident.
      </SubHeader>

      {/* Key Features Section */}
      <FeaturesSection>
        <FeaturesTitle>What You Can Do with Ressydent</FeaturesTitle>
        <FeaturesGrid>
          <FeatureCard>
            <FeatureTitle>Electricity Vending</FeatureTitle>
            <FeatureDescription>
              Easily purchase electricity tokens for your home, view your meter status, and track consumption.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard>
            <FeatureTitle>Service Charge Payments</FeatureTitle>
            <FeatureDescription>
              Pay your estate service fees quickly and securely, with detailed records of past payments.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard>
            <FeatureTitle>Water & Diesel Deposits</FeatureTitle>
            <FeatureDescription>
              Manage deposits for water and diesel supply with clear visibility of your account balance.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard>
            <FeatureTitle>Visitor Access Codes</FeatureTitle>
            <FeatureDescription>
              Generate unique access codes for your visitors and manage visitor logs efficiently.
            </FeatureDescription>
          </FeatureCard>
        </FeaturesGrid>
      </FeaturesSection>

      {/* Call to Action */}
      <CallToAction>
        <CTAButton href="/about">Learn More About Ressydent</CTAButton>
      </CallToAction>
      <BottomBar />
    </HomeContainer>
  );
};

export default Home;