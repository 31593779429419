import React, { useState } from 'react';
import { Resident } from '../types/Resident';
import { FaEye, FaToggleOn, FaToggleOff } from 'react-icons/fa'; // Font Awesome Icons

export const ActionsDropdown: React.FC<{ 
    resident: Resident; 
    onAction: (action: string, residentId: number) => void;
  }> = ({ resident, onAction }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [confirmAction, setConfirmAction] = useState<null | string>(null); // Track confirmation modal state
  
    const handleAction = (action: string) => {
      if (action === 'activate' || action === 'deactivate') {
        setConfirmAction(action); // Show confirmation modal for sensitive actions
      } else {
        onAction(action, resident.id);
        setShowDropdown(false);
      }
    };
  
    const confirmSensitiveAction = () => {
      onAction(confirmAction!, resident.id); // Proceed with action
      setConfirmAction(null); // Close modal
      setShowDropdown(false);
    };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {/* Dropdown Button */}
      <button
        onClick={() => setShowDropdown(prev => !prev)}
        style={{
          backgroundColor: '#1a1a2e',
          color: 'white',
          padding: '8px 16px',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          transition: 'background-color 0.2s ease',
        }}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#16213e')}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#1a1a2e')}
      >
        Actions
      </button>

      {/* Dropdown List */}
      {showDropdown && (
        <ul
        style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          backgroundColor: '#fff',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          listStyle: 'none',
          padding: '10px 0',
          margin: 0,
          borderRadius: '4px',
          zIndex: 1000,
          width: '200px',
        }}
      >
          {/* View Details Option */}
          <li
            style={{ padding: '10px 16px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => handleAction('viewDetails')}
          >
            <FaEye style={{ marginRight: '8px', color: '#1a1a2e' }} />
            View Details
          </li>

          {/* Activate/Deactivate Option */}
          <li
            style={{ padding: '10px 16px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => handleAction(resident.active = true ? 'deactivate' : 'activate')}
          >
            {resident.active ? (
              <>
                <FaToggleOff style={{ marginRight: '8px', color: '#e63946' }} />
                Deactivate
              </>
            ) : (
              <>
                <FaToggleOn style={{ marginRight: '8px', color: '#2a9d8f' }} />
                Activate
              </>
            )}
          </li>
        </ul>
      )}

      {/* Confirmation Modal */}
      {confirmAction && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1100,
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              textAlign: 'center',
              maxWidth: '400px',
              width: '100%',
            }}
          >
            <h3>Confirm Action</h3>
            <p>
              Are you sure you want to{' '}
              <strong style={{ color: confirmAction === 'deactivate' ? '#e63946' : '#2a9d8f' }}>
                {confirmAction}
              </strong>{' '}
              this resident?
            </p>
            <div style={{ marginTop: '20px' }}>
              <button
                onClick={confirmSensitiveAction}
                style={{
                  padding: '10px 20px',
                  marginRight: '10px',
                  backgroundColor: '#2a9d8f',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Confirm
              </button>
              <button
                onClick={() => setConfirmAction(null)}
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#e63946',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Keyframe Animation for Fade-in */}
      <style>
        {`
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(-10px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }

          @media (max-width: 768px) {
            ul {
                width: 100%; /* Dropdown spans full width on mobile */
                left: 0;
            }

            button {
                padding: 10px; /* Larger touch targets for smaller screens */
            }
            div[style*='fixed'] {
                padding: 20px; /* Ensure modal content fits on smaller screens */
            }
            }
        `}
      </style>
    </div>
  );
};