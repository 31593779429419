import axios from 'axios';

// const API_BASE_URL = 'https://api.routewatche.com/api';
const API_BASE_URL = 'http://localhost:3001/api';

export const getResidents = async () => {
  const response = await axios.get(`${API_BASE_URL}/admin/users`);
  console.log('residents fetch', response.data);
  return response.data;
};

export const updateResidentStatus = async (id: number, status: string) => {
  const response = await axios.put(`${API_BASE_URL}/residents/${id}/status`, { status });
  return response.data;
};