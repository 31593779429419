import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { saveAs } from 'file-saver';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TransactionService from '../../services/TransactionService';
import { Pagination } from '../../components/Pagination';
import autoTable from 'jspdf-autotable';
import ErrorBoundary from '../../components/ErrorBoundary';

interface Transaction {
    date: string;
    type: string;
    status: string;
    amount: number;
    meterNumber: string;
}

const TransactionReport: React.FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [filteredTransactions, setFilteredTransactions] = useState<Transaction[]>([]);
  const [filters, setFilters] = useState<{
    fromDate: Date | null;
    toDate: Date | null;
    type: string;
    status: string;
    minAmount: number;
    maxAmount: number;
    meterNumber: string;
  }>({
    fromDate: null,
    toDate: null,
    type: '',
    status: '',
    minAmount: 0,
    maxAmount: 10000,
    meterNumber: '',
  });  
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

//   autoTable(jsPDF);
  
  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    try {
      const result = await TransactionService.fetchAllTransactions();
      setTransactions(result);
      setFilteredTransactions(result);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const applyFilters = async () => {
    try {
      const filteredTransactions = await TransactionService.getFilteredTransactions(filters);
      setTransactions(filteredTransactions);
    } catch (error) {
      console.error("Failed to apply filters:", error);
    }
  };  
  

  const downloadPDF = (transactions: any[]) => {
    const doc = new jsPDF();
    // doc.setFontSize(12);
  
    // Add a title
    doc.text("Transaction Report", 10, 10);
  
    // Prepare table headers and rows
    const headers = ["Type", "Amount", "Status", "Meter Number"];
    const rows = transactions.map((transaction) => [
      transaction.type,
      transaction.amount,
      transaction.status,
      transaction.meterNumber,
    ]);
  
    // console.log('doc', doc);
    // Add the table
    (doc as any).autoTable(
      headers,
      rows,
      {startY: 20, // Start after the title
    });
  
    // Save the PDF
    doc.save("transaction_report.pdf");
  };  

const downloadExcel = async () => {
  try {
    const filteredTransactions = await TransactionService.getFilteredTransactions(filters);
    const worksheet = XLSX.utils.json_to_sheet(filteredTransactions);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");

    XLSX.writeFile(workbook, "Transaction_Report.xlsx");
  } catch (error) {
    console.error("Failed to download Excel:", error);
  }
};

  let paginatedTransactions = filteredTransactions.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  paginatedTransactions = Array.from(paginatedTransactions);


  return (
    <ErrorBoundary>
    <div style={{ padding: '20px' }}>
      <h1>Transaction Report</h1>

      {/* Filters */}
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginBottom: '20px' }}>
        <DatePicker
          selected={filters.fromDate}
          onChange={(date) => setFilters((prev) => ({ ...prev, fromDate: date }))}
          placeholderText="From Date"
        />
        <DatePicker
          selected={filters.toDate}
          onChange={(date) => setFilters((prev) => ({ ...prev, toDate: date }))}
          placeholderText="To Date"
        />
        <select
          value={filters.type}
          onChange={(e) => setFilters((prev) => ({ ...prev, type: e.target.value }))}
        >
          <option value="">All Types</option>
          <option value="Power">Power</option>
          <option value="Service Fee">Service Fee</option>
          <option value="Water Deposit">Water Deposit</option>
          <option value="Diesel Deposit">Diesel Deposit</option>
        </select>
        <select
          value={filters.status}
          onChange={(e) => setFilters((prev) => ({ ...prev, status: e.target.value }))}
        >
          <option value="">All Statuses</option>
          <option value="successful">Successful</option>
          <option value="pending">Pending</option>
          <option value="canceled">Canceled</option>
          <option value="failed">Failed</option>
        </select>
        <input
          type="number"
          placeholder="Min Amount"
          value={filters.minAmount}
          onChange={(e) => setFilters((prev) => ({ ...prev, minAmount: parseInt(e.target.value) }))}
        />
        <input
          type="number"
          placeholder="Max Amount"
          value={filters.maxAmount}
          onChange={(e) => setFilters((prev) => ({ ...prev, maxAmount: parseInt(e.target.value) }))}
        />
        <input
          type="text"
          placeholder="Meter Number"
          value={filters.meterNumber}
          onChange={(e) => setFilters((prev) => ({ ...prev, meterNumber: e.target.value }))}
        />
        <button onClick={applyFilters}>Apply Filters</button>
      </div>

      {/* Export Buttons */}
      <div style={{ marginBottom: '20px' }}>
        <CSVLink
          data={filteredTransactions}
          filename="Transaction_Report.csv"
          style={{
            padding: '10px 15px',
            backgroundColor: '#007bff',
            color: 'white',
            textDecoration: 'none',
            marginRight: '10px',
            borderRadius: '4px',
          }}
        >
          Export CSV
        </CSVLink>
        <button
            onClick={() => downloadPDF(filteredTransactions)} 
            style={{ padding: '10px 15px', backgroundColor: '#28a745', color: 'white', borderRadius: '4px' }}
            >
            Export PDF
        </button>

        <button onClick={downloadExcel} style={{ padding: '10px 15px', backgroundColor: '#ffc107', color: 'white', borderRadius: '4px' }}>
          Export Excel
        </button>
      </div>

      {/* Table */}
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Date</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Type</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Status</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Amount</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Meter Number</th>
          </tr>
        </thead>
        <tbody>
          {paginatedTransactions?.map((transaction, index) => (
            <tr key={index}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{transaction.date}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{transaction.type}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{transaction.status}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{transaction.amount}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{transaction.meterNumber}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        totalItems={filteredTransactions.length}
        rowsPerPage={rowsPerPage}
        onPageChange={setCurrentPage}
      />
    </div>
    </ErrorBoundary>
  );
};

export default TransactionReport;