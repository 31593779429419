import React from 'react';
import styled from 'styled-components';

const BottomBarContainer = styled.footer`
  padding: 20px;
  background-color: #2c3e50;
  color: white;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
`;

const Copyright = styled.p`
  margin: 0;
`;

const BottomBar: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <BottomBarContainer>
      <Copyright>
        &copy; {currentYear} Ressydent. Powered by Ressytech Limited.
      </Copyright>
    </BottomBarContainer>
  );
};

export default BottomBar;