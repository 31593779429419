import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Resident } from '../../types/Resident'; // Import the type
import { useDebounce } from '../../hooks/useDebounce';
import { Pagination } from '../../components/Pagination';
import { ActionsDropdown } from '../../components/ActionsDropdown';
import { getResidents, updateResidentStatus } from '../../services/ResidentService';
import { ClipLoader } from 'react-spinners';
import { Modal } from '../../components/Modal';

const ResponsiveTableWrapper = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;  /* Ensure smooth scrolling on iOS */
  margin-top: 20px;
`;

const ResidentTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed;  /* Ensures table columns don't stretch too wide */

  th, td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }

  th {
    background-color: #1a1a2e;
    color: white;
  }

  @media (max-width: 768px) {
    th, td {
      font-size: 14px;  /* Adjust font size on smaller screens */
      padding: 8px;
    }
  }
`;

const FilterContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;  /* Allow items to wrap on small screens */
  gap: 10px;
  align-items: center;

  select, button {
    max-width: 200px;  /* Limit width of select and buttons */
    width: 100%;
  }

  @media (max-width: 768px) {
    justify-content: space-between;  /* Ensure spacing between elements */
  }

  @media (max-width: 480px) {
    flex-direction: column;  /* Stack items vertically on very small screens */
    align-items: stretch;  /* Make elements stretch to full width */
  }
`;


const Button = styled.button`
  background-color: #1a1a2e;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #333;
  }

  @media (max-width: 768px) {
    width: 100%;  /* Make the button full width on smaller screens */
  }
`;

const SelectInput = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;


const ResidentManagementWrapper = styled.div`
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  @media (max-width: 480px) {
    h1 {
      font-size: 20px;
    }
  }
`;

const ResidentManagement: React.FC = () => {
    const [loading, setLoading] = useState(true); // Initialize with true since data isn't loaded initially
    const [residents, setResidents] = useState<Resident[]>([]);
    const [filteredResidents, setFilteredResidents] = useState<Resident[]>([]);
    const [currentResidents, setCurrentResidents] = useState<Resident[]>([]); // Paginated residents
    const [modalData, setModalData] = useState<Resident>();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [emailQuery, setEmailQuery] = useState('');
    const [phoneQuery, setPhoneQuery] = useState('');
    const [estateFilter, setEstateFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [selectedResidents, setSelectedResidents] = useState<Set<number>>(new Set());
    const [bulkAction, setBulkAction] = useState<'activate' | 'deactivate' | 'delete' | ''>('');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedResident, setSelectedResident] = useState<Resident | null>(null);
    const itemsPerPage = 10; // Number of residents per page

    // Determine the residents to display for the current page
    const indexOfLastResident = currentPage * itemsPerPage;
    const indexOfFirstResident = indexOfLastResident - itemsPerPage;
    

    // Change page
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);


    // Debounced inputs
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  const debouncedEmailQuery = useDebounce(emailQuery, 300);
  const debouncedPhoneQuery = useDebounce(phoneQuery, 300);

  useEffect(() => {
    fetchResidents();
  }, []);

  const fetchResidents = async () => {
    try {
      console.log("in fetch residents");
      setLoading(true);
      const data = await getResidents();
      console.log('gotten residents', data.data);
      
      // Update state
      setResidents(data.data);
      setFilteredResidents(data.data);
      setLoading(false);
  
      // Use the data directly for computations
      // currentResidents = data.data.slice(indexOfFirstResident, indexOfLastResident);
      console.log('current residents', currentResidents);
  
    } catch (error) {
      console.error('Failed to fetch residents:', error);
    }
  };
  
  // Use useEffect to monitor residents and log changes
  useEffect(() => {
    // Update current residents when filteredResidents changes
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setCurrentResidents(filteredResidents.slice(startIndex, endIndex));
  }, [filteredResidents, currentPage]);

  const handleViewDetails = (id: number) => {
    const resident = residents.find(resident => resident.id === id);
    setSelectedResident(resident || null);
    setModalData(resident); // Pass resident data to modal
    setModalVisible(true); 
  };
  
  const handleCloseDetails = () => {
    setSelectedResident(null);
  };

  const handleStatusChange = async (id: number, newStatus: 'active' | 'inactive') => {
    try {
      await updateResidentStatus(id, newStatus);
      fetchResidents(); // Refresh the list after update
    } catch (error) {
      console.error('Failed to update resident status:', error);
    }
  };

  const handleSearch = (queryType: string, value: string) => {
    switch (queryType) {
      case 'name':
        setSearchQuery(value);
        break;
      case 'email':
        setEmailQuery(value);
        break;
      case 'phone':
        setPhoneQuery(value);
        break;
      case 'estate':
        setEstateFilter(value);
        break;
      case 'status':
        setStatusFilter(value);
        break;
    }
    filterResidents(
      debouncedSearchQuery, // updated search query
      debouncedEmailQuery,  // updated email query
      debouncedPhoneQuery,  // updated phone query
      estateFilter,        // estate filter
      statusFilter         // status filter
    );
  };
  
  const handleStatusFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatusFilter(e.target.value);
    filterResidents(debouncedSearchQuery, debouncedEmailQuery, debouncedPhoneQuery, estateFilter, e.target.value);
  };

  const filterResidents = (
    searchQuery: string,
    emailQuery: string,
    phoneQuery: string,
    estateFilter: string,
    statusFilter: string
  ) => {
    let filtered = residents;

    if (debouncedSearchQuery)
      filtered = filtered.filter(resident => resident.name.toLowerCase().includes(debouncedSearchQuery.toLowerCase()));
    if (debouncedEmailQuery)
      filtered = filtered.filter(resident => resident.email.toLowerCase().includes(debouncedEmailQuery.toLowerCase()));
    if (debouncedPhoneQuery)
      filtered = filtered.filter(resident => resident.estateusers?.phonenumber?.includes(debouncedPhoneQuery));
    if (estateFilter)
      filtered = filtered.filter(resident => resident.estateusers?.estates?.name === estateFilter);
    if (statusFilter)
      filtered = filtered.filter(resident => resident.active === statusFilter);

    setFilteredResidents(filtered);
  };

  const highlightText = (text: string, highlight: string) => {
    if (!highlight) return text;
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span>
      ) : (
        part
      )
    );
  };

  const handleResidentSelect = (id: number) => {
    const newSelectedResidents = new Set(selectedResidents);
    if (newSelectedResidents.has(id)) {
      newSelectedResidents.delete(id);
    } else {
      newSelectedResidents.add(id);
    }
    setSelectedResidents(newSelectedResidents);
  };
  
  const handleSelectAll = () => {
    if (selectedResidents.size === filteredResidents.length) {
      setSelectedResidents(new Set());
    } else {
      const newSelectedResidents = new Set(filteredResidents.map(resident => resident.id));
      setSelectedResidents(newSelectedResidents);
    }
  }; 

  const handleBulkAction = async () => {
    if (selectedResidents.size === 0 || !bulkAction) return;
  
    try {
      const ids = Array.from(selectedResidents);
      switch (bulkAction) {
        case 'activate':
          await Promise.all(ids.map(id => updateResidentStatus(id, 'active')));
          break;
        case 'deactivate':
          await Promise.all(ids.map(id => updateResidentStatus(id, 'inactive')));
          break;
        case 'delete':
          // Assuming there's a delete function in the residentService
        //   await Promise.all(ids.map(id => deleteResident(id)));
          break;
        default:
          break;
      }
      fetchResidents(); // Refresh the list after the action
      setSelectedResidents(new Set()); // Reset selected residents
      setBulkAction(''); // Reset the bulk action
    } catch (error) {
      console.error('Failed to perform bulk action:', error);
    }
  };  

  const handleResidentAction = (action: string, residentId: number) => {
    if (action === 'viewDetails') {
      handleViewDetails(residentId);
    } else if (action === 'activate') {
      // Add logic to activate the resident
      console.log(`Activating resident with ID: ${residentId}`);
    } else if (action === 'deactivate') {
      // Add logic to deactivate the resident
      console.log(`Deactivating resident with ID: ${residentId}`);
    }
  };  

  return (
    <ResidentManagementWrapper>
      <h1>Resident Management</h1>

      <FilterContainer>
        <input
          type="text"
          placeholder="Search by name"
          value={searchQuery}
          onChange={(e) => handleSearch('name', e.target.value)} // Call handleSearch for name filter
        />
        <input
          type="email"
          placeholder="Search by email"
          value={emailQuery}
          onChange={(e) => handleSearch('email', e.target.value)} // Call handleSearch for email filter
        />
        <input
          type="text"
          placeholder="Search by phone"
          value={phoneQuery}
          onChange={(e) => handleSearch('phone', e.target.value)} // Call handleSearch for phone filter
        />
        <select value={estateFilter} onChange={(e) => handleSearch('estate', e.target.value)}>  {/* Call handleSearch for estate filter */}
          <option value="">All Estates</option>
          <option value="Estate A">Estate A</option>
          <option value="Estate B">Estate B</option>
        </select>
        <select value={statusFilter} onChange={handleStatusFilter}>  {/* Use handleStatusFilter for status filter */}
          <option value="">All Statuses</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
        <select value={bulkAction} onChange={(e) => setBulkAction(e.target.value as 'activate' | 'deactivate' | 'delete')}>
            <option value="">Select Bulk Action</option>
            <option value="activate">Activate</option>
            <option value="deactivate">Deactivate</option>
            <option value="delete">Delete</option>
        </select>
        <Button onClick={handleBulkAction}>Apply</Button>
      </FilterContainer>

      {!loading && filteredResidents.length === 0 && (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <span>No residents found.</span>
        </div>
      )}

<ResponsiveTableWrapper>
  {loading ? (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <ClipLoader size={50} color="#3498db" loading={loading} />
      <p>Loading residents...</p>
    </div>
  ): (
    <>
      <ResidentTable>
        <thead>
          <tr>
            <th style={{ whiteSpace: 'nowrap', padding: '10px', textAlign: 'left' }}>Name</th>
            <th style={{ whiteSpace: 'nowrap', padding: '10px', textAlign: 'left' }}>Email</th>
            <th style={{ whiteSpace: 'nowrap', padding: '10px', textAlign: 'left' }}>Phone</th>
            <th style={{ whiteSpace: 'nowrap', padding: '10px', textAlign: 'left' }}>Status</th>
            <th style={{ whiteSpace: 'nowrap', padding: '10px', textAlign: 'left' }}>Estate</th>
            <th style={{ whiteSpace: 'nowrap', padding: '10px', textAlign: 'left' }}>Actions</th>
            <th>
              <input 
                type="checkbox" 
                checked={selectedResidents.size === filteredResidents.length} 
                onChange={handleSelectAll} 
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {currentResidents.map((resident: Resident) => (
            <tr key={resident.id}>
              <td style={{ wordWrap: 'break-word', padding: '10px' }}>{resident.name}</td>
              <td style={{ wordWrap: 'break-word', padding: '10px' }}>{resident.email}</td>
              <td style={{ wordWrap: 'break-word', padding: '10px' }}>{resident.estateusers?.phonenumber || 'N/A'}</td>
              <td style={{ wordWrap: 'break-word', padding: '10px' }}>{resident.active === "1" ? 'Active' : 'Inactive'}</td>
              <td style={{ wordWrap: 'break-word', padding: '10px' }}>{resident.estateusers?.estates?.name || 'N/A'}</td>
              <td>
                <ActionsDropdown resident={resident} onAction={handleResidentAction} />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={selectedResidents.has(resident.id)}
                  onChange={() => handleResidentSelect(resident.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </ResidentTable>
      {/* Modal for viewing details */}
      {modalData && (
        <Modal 
          title="Resident Details" 
          isVisible={modalVisible} 
          onClose={() => setModalVisible(false)}
          modalData={modalData}
        >
          <p><strong>Name:</strong> {modalData.name}</p>
          <p><strong>Email:</strong> {modalData.email}</p>
          <p><strong>Phone:</strong> {modalData.estateusers?.phonenumber || 'N/A'}</p>
          <p><strong>Status:</strong> {modalData.active === "1" ? 'Active' : 'Inactive'}</p>
          <p><strong>Estate:</strong> {modalData.estateusers?.estates?.name || 'N/A'}</p>
        </Modal>
      )}
      <Pagination
        rowsPerPage={itemsPerPage}
        totalItems={filteredResidents.length}
        onPageChange={paginate}
        currentPage={currentPage}
      />
    </>
  )}
</ResponsiveTableWrapper>

      {/* Bulk Actions */}
      {/* <div>
        <select value={bulkAction} onChange={(e) => setBulkAction(e.target.value as any)}>
          <option value="">Select Action</option>
          <option value="activate">Activate</option>
          <option value="deactivate">Deactivate</option>
          <option value="delete">Delete</option>
        </select>
        <Button onClick={handleBulkAction}>Apply Bulk Action</Button>
      </div> */}
      </ResidentManagementWrapper>
  );
};

export default ResidentManagement;