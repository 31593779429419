import axios from 'axios';
import React, { useState } from 'react';
import { Resident } from '../types/Resident';

interface ModalProps {
  title: string;
  isVisible: boolean;
  onClose: () => void;
  modalData: Resident;
  children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ title, isVisible, onClose, modalData, children }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [services, setServices] = useState<{ name: string; active: boolean }[]>([]);

  const fetchAdditionalDetails = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`/api/residents/${modalData.id}/details`); // Update API endpoint
      setTransactions(data.transactions || []);
      setServices(data.services || []);
      setShowDetails(true);
    } catch (error) {
      console.error("Error fetching additional details:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleService = async (serviceName: string, isActive: boolean) => {
    try {
      await axios.post(`/api/residents/${modalData.id}/services/toggle`, {
        service: serviceName,
        active: !isActive,
      });
      setServices((prev) =>
        prev.map((service) =>
          service.name === serviceName ? { ...service, active: !isActive } : service
        )
      );
    } catch (error) {
      console.error("Error toggling service:", error);
    }
  };

  if (!isVisible) return null;

  return (
    <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1000 }}>
      <div style={{ margin: "50px auto", padding: "20px", background: "white", borderRadius: "8px", maxWidth: "800px", width: "100%" }}>
        <h2>{title}</h2>
        <button style={{ float: "right", cursor: "pointer" }} onClick={onClose}>
          ✖
        </button>

        <section>
          <p><strong>Name:</strong> {modalData.name}</p>
          <p><strong>Email:</strong> {modalData.email}</p>
          <p><strong>Phone:</strong> {modalData.estateusers?.phonenumber || "N/A"}</p>
          <p><strong>Status:</strong> {modalData.active === "1" ? "Active" : "Inactive"}</p>
          <p><strong>Estate:</strong> {modalData.estateusers?.estates?.name || "N/A"}</p>
        </section>

        {!showDetails ? (
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <button
                onClick={fetchAdditionalDetails}
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#007BFF",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                View More
              </button>
            )}
          </div>
        ) : (
          <>
            <section style={{ marginTop: "20px" }}>
              <h3>Resident's Last 5 Transactions</h3>
              {transactions.length > 0 ? (
                <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20px" }}>
                  <thead>
                    <tr>
                      <th style={{ borderBottom: "1px solid #ccc", padding: "8px" }}>Date</th>
                      <th style={{ borderBottom: "1px solid #ccc", padding: "8px" }}>Service Type</th>
                      <th style={{ borderBottom: "1px solid #ccc", padding: "8px" }}>Amount</th>
                      <th style={{ borderBottom: "1px solid #ccc", padding: "8px" }}>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((txn: any, idx: number) => (
                      <tr key={idx}>
                        <td style={{ padding: "8px" }}>{txn.date}</td>
                        <td style={{ padding: "8px" }}>{txn.serviceType}</td>
                        <td style={{ padding: "8px" }}>{txn.amount}</td>
                        <td style={{ padding: "8px" }}>{txn.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No transactions found.</p>
              )}
            </section>

            <section>
              <h3>Available Services</h3>
              <ul style={{ listStyle: "none", padding: 0 }}>
                {services.map((service) => (
                  <li key={service.name} style={{ display: "flex", justifyContent: "space-between", padding: "10px 0" }}>
                    <span>{service.name}</span>
                    <button
                      onClick={() => toggleService(service.name, service.active)}
                      style={{
                        backgroundColor: service.active ? "#e63946" : "#2a9d8f",
                        color: "white",
                        border: "none",
                        padding: "5px 10px",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      {service.active ? "Disable" : "Enable"}
                    </button>
                  </li>
                ))}
              </ul>
            </section>
          </>
        )}
      </div>
    </div>
  );
};