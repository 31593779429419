import React from 'react';
import styled from 'styled-components';
import TopNavBar from '../components/TopNavBar';
import BottomBar from '../components/BottomBar';

const AboutContainer = styled.div`
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

const Header = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #2c3e50;
`;

const Description = styled.p`
  font-size: 1.2rem;
  color: #7f8c8d;
  line-height: 1.6;
`;

const Section = styled.section`
  margin: 40px 0;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  color: #2980b9;
`;

const SectionContent = styled.p`
  font-size: 1rem;
  color: #7f8c8d;
  line-height: 1.6;
`;

const ContactSection = styled.div`
  margin-top: 40px;
`;

const ContactInfo = styled.p`
  font-size: 1rem;
  color: #34495e;
  margin: 5px 0;
`;

const About: React.FC = () => {
  return (
    <AboutContainer>
        <TopNavBar />
      <Header>About Ressydent</Header>
      <Description>
        Ressydent is a comprehensive facility management platform designed to simplify life for estate residents.
        Our platform allows you to easily manage service payments, electricity vending, and more, from the
        comfort of your home. We bridge the gap between service providers and residents, offering a seamless
        experience for all your utility management needs.
      </Description>

      <Section>
        <SectionTitle>Our Mission</SectionTitle>
        <SectionContent>
          Our mission is to empower estate residents by providing them with a centralized platform to manage
          their utilities, track payments, and ensure smooth communication with estate managers. We are committed
          to simplifying everyday tasks and improving the quality of estate living.
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle>What We Offer</SectionTitle>
        <SectionContent>
          At Ressydent, we offer features like:
          <ul>
            <li>Electricity vending</li>
            <li>Service charge management</li>
            <li>Water and diesel deposit payments</li>
            <li>Transaction history tracking</li>
            <li>Visitor access code generation</li>
            <li>Business advertisements for residents</li>
          </ul>
          With our user-friendly interface and secure payment processing, you can take control of your estate
          management with ease.
        </SectionContent>
      </Section>
      <BottomBar />
    </AboutContainer>
  );
};

export default About;