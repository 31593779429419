import axios from 'axios';

class TransactionService {
  private baseUrl: string = 'https://api.routewatche.com';

  constructor() {
    // Base URL for API calls
    this.baseUrl = '/api/reports/transactions';
  }

  /**
   * Fetch all transactions
   * @returns Promise containing transaction data
   */
  async fetchAllTransactions(): Promise<any> {
    try {
      const response = await axios.get(this.baseUrl);
      return response.data;
    } catch (error) {
      console.error('Error fetching transactions:', error);
      throw error;
    }
  }

  /**
   * Fetch filtered transactions
   * @param filters Object containing the filter parameters
   * @returns Promise containing filtered transaction data
   */
  async fetchFilteredTransactions(filters: {
    fromDate?: string | null;
    toDate?: string | null;
    type?: string;
    status?: string;
    minAmount?: number;
    maxAmount?: number;
    meterNumber?: string;
  }): Promise<any> {
    try {
      const response = await axios.get(this.baseUrl, { params: filters });
      return response.data;
    } catch (error) {
      console.error('Error fetching filtered transactions:', error);
      throw error;
    }
  }

 async getFilteredTransactions(filters: {
    fromDate: Date | null;
    toDate: Date | null;
    type: string;
    status: string;
    minAmount: number;
    maxAmount: number;
    meterNumber: string;
  }) {
    const response = await fetch("/api/transactions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(filters),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch transactions");
    }

    return await response.json();
  }

  /**
   * Export transactions as PDF
   * @param filters Object containing the filter parameters
   * @returns Blob containing PDF data
   */
  async exportTransactionsAsPDF(filters: {
    fromDate?: string | null;
    toDate?: string | null;
    type?: string;
    status?: string;
    minAmount?: number;
    maxAmount?: number;
    meterNumber?: string;
  }): Promise<Blob> {
    try {
      const response = await axios.post(`${this.baseUrl}/export/pdf`, filters, {
        responseType: 'blob', // Needed for downloading files
      });
      return response.data;
    } catch (error) {
      console.error('Error exporting transactions as PDF:', error);
      throw error;
    }
  }

  /**
   * Export transactions as Excel
   * @param filters Object containing the filter parameters
   * @returns Blob containing Excel data
   */
  async exportTransactionsAsExcel(filters: {
    fromDate?: string | null;
    toDate?: string | null;
    type?: string;
    status?: string;
    minAmount?: number;
    maxAmount?: number;
    meterNumber?: string;
  }): Promise<Blob> {
    try {
      const response = await axios.post(`${this.baseUrl}/export/excel`, filters, {
        responseType: 'blob', // Needed for downloading files
      });
      return response.data;
    } catch (error) {
      console.error('Error exporting transactions as Excel:', error);
      throw error;
    }
  }
}

export default new TransactionService();